<script>
import TablePage from '../../../../../../../components/table_page';

export default {
  extends: TablePage,
  components: {
  },
  props: {
    poolCode: String,
    conditionInfo: Object,
    operationType: String,
  },
  data() {
    return {
      requestUrl: '',
      formConfig: {},
      selection: [],
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  watch: {
    operationType(n) {
      this.params.operationType = n;
      this.getList();
    },
  },
  created() {
    this.params.poolCode = this.poolCode;
    this.getConfigList('dms_discount_condition_detail');
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'fileList') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => {
          if (cellValue && cellValue.length > 0) {
            const list = [];
            cellValue.forEach((val) => {
              list.push(`<a href=${val.url}>${val.fileName}</a>`);
            });
            return list.join(',');
          }
          return '';
        };
      }
      return rowData;
    },
    modalClick({ val }) {
      console.log(val);
      this.openModal();
      return true;
    },
  },
};
</script>
